var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-button-group',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(_vm.modal),expression:"modal"}],staticClass:"btn-sm btn-icon mr-1 rounded",attrs:{"variant":"secondary","title":"Ver Movimientos"}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1),(_vm.$can('create', _vm.modulo))?_c('b-button',{staticClass:"btn-sm btn-icon mr-1 rounded",attrs:{"variant":"secondary","title":"Duplicar"},on:{"click":function($event){return _vm.$emit('processGoToClone', _vm.data.item)}}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}})],1):_vm._e(),_c('b-button',{staticClass:"btn-sm btn-icon mr-1 rounded",attrs:{"variant":"secondary","title":_vm.$can('update', _vm.modulo)
      ? 'Editar'
      : ("No tienes permisos para editar " + (_vm.primeraMayuscula(_vm.modulo))),"disabled":!_vm.$can('update', _vm.modulo)},on:{"click":function($event){_vm.$can('update', _vm.modulo)
      ? _vm.$emit('processGoToUpdate', _vm.data.item)
      : ''}}},[_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1),_c('b-button',{staticClass:"btn-sm btn-icon rounded",attrs:{"variant":"secondary","title":_vm.$can('delete', _vm.modulo)
      ? !_vm.estado
        ? _vm.titleDelete
        : 'Eliminar'
      : ("No tienes permisos para eliminar " + (_vm.primeraMayuscula(_vm.modulo))),"disabled":!_vm.estado || !_vm.$can('delete', _vm.modulo)},on:{"click":function($event){_vm.$can('delete', _vm.modulo)
      ? !_vm.estado
        ? ''
        : _vm.$emit('processRemove', _vm.data.item.id)
      : ''}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }