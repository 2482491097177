<template>
  <b-button-group>
    <b-button
      variant="secondary"
      class="btn-sm btn-icon mr-1 rounded"
      title="Ver Movimientos"
      v-b-modal="modal"
    >
      <feather-icon
        icon="EyeIcon"
      />
    </b-button>
    <b-button
      v-if="$can('create', modulo)"
      variant="secondary"
      class="btn-sm btn-icon mr-1 rounded"
      title="Duplicar"
      @click="$emit('processGoToClone', data.item)"
    >
      <feather-icon
        icon="CopyIcon"
      />
    </b-button>
    <b-button
      variant="secondary"
      class="btn-sm btn-icon mr-1 rounded"
      :title="$can('update', modulo)
        ? 'Editar'
        : `No tienes permisos para editar ${primeraMayuscula(modulo)}`"
      @click="$can('update', modulo)
        ? $emit('processGoToUpdate', data.item)
        : ''"
      :disabled="!$can('update', modulo)"
    >
      <feather-icon
        icon="Edit2Icon"
      />
    </b-button>
    <b-button
      variant="secondary"
      class="btn-sm btn-icon rounded"
      @click="$can('delete', modulo)
        ? !estado
          ? ''
          : $emit('processRemove', data.item.id)
        : ''"
      :title="$can('delete', modulo)
        ? !estado
          ? titleDelete
          : 'Eliminar'
        : `No tienes permisos para eliminar ${primeraMayuscula(modulo)}`"
      :disabled="!estado || !$can('delete', modulo)"
    >
      <feather-icon
        icon="TrashIcon"
      />
    </b-button>
  </b-button-group>
</template>

<script>
import {
  BButton, BButtonGroup,
} from 'bootstrap-vue'
import { formatos } from '@/mixins/forms'

export default {
  components: {
    BButton,
    BButtonGroup,
  },
  mixins: [formatos],
  props: {
    data: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: true,
    },
    modal: {
      type: String,
      required: true,
    },
  },
  computed: {
    estado() {
      const { estado } = this.data.item.destroy
      return estado
    },
    titleDelete() {
      return this.getTitleDelete()
    },
  },
  methods: {
    getTitleDelete() {
      const { cantidadSalidas } = this.data.item.destroy
      const { cantidadComprasPendientes } = this.data.item.destroy
      const { cantidadComprasAprobados } = this.data.item.destroy
      const titleSalida = cantidadSalidas <= 1
        ? 'Salida' : 'Salidas'
      const titleOCPendiente = cantidadComprasPendientes <= 1
        ? 'OC (Pendiente)' : 'OCs (Pendiente)'

      const titleOCAprobado = cantidadComprasAprobados <= 1
        ? 'OC (Aprobado)' : 'OCs (Aprobado)'

      return `- ${titleSalida}: ${cantidadSalidas}
- ${titleOCPendiente}: ${cantidadComprasPendientes}
- ${titleOCAprobado}: ${cantidadComprasAprobados}`
    },
  },
}
</script>
